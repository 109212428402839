'use client';
import React from 'react';
import { signIn } from 'next-auth/react';
import { LanguageSwitcher } from '../../organisms/LanguageSwitcher';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/ui/button';
import IconArrowRight from '../../../public/images/icon-arrow-right-white.svg';

export default function LoginButton() {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.login_button}>
        <div className={styles.row}>
          <p>{t('already_expert')}</p>
          <LanguageSwitcher />
        </div>
        <Button
          className="py-7 px-8"
          onClick={() => {
            if (process.env.FAKE_AUTH === 'true') {
              window.location.href = '/login';
            } else {
              signIn('okta', { callbackUrl: '/dashboard' });
            }
          }}
        >
          {t('login_to_dashboard')}
          <IconArrowRight className="w-4 h-4" />
        </Button>
      </div>
    </>
  );
}
