import { useEffect } from 'react';

const SCROLL_WIDTH = 15; //On globals css

const clickedOnScrollbar = (mouseX) => {
  if (window.innerWidth - SCROLL_WIDTH <= mouseX) {
    return true;
  }
  return false;
};

// Hook
export default function useOutsideClickMultiple(
  refs: any[] = [],
  handler: (a?: any) => void,
  handleScroll: boolean = true //handle scroll click,
) {
  useEffect(() => {
    const listener = (e, ref) => {
      if (!ref?.current || ref?.current?.contains(e?.target)) return false;
      return true;
    };

    const scrollListener = (e) => {
      if (clickedOnScrollbar(e.clientX)) return false;
      return true;
    };

    const listenerWrapper = (e) => {
      if (handleScroll) {
        scrollListener(e) && refs?.every((r) => listener(e, r)) && handler(e);
      } else {
        refs?.every((r) => listener(e, r)) && handler(e);
      }
      return;
    };

    document.addEventListener('mousedown', listenerWrapper, true);
    document.addEventListener('touchstart', listenerWrapper, true);
    return () => {
      document.removeEventListener('mousedown', listenerWrapper, true);
      document.removeEventListener('touchstart', listenerWrapper, true);
    };
  }, [...refs, handler]);
}
